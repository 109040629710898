var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('user-dashboard-layout',[_c('v-row',[_c('v-col',[_c('v-select',{attrs:{"items":_vm.unboundTypes,"label":((_vm.$t('general.template-select-placeholder')) + ":"),"outlined":""},model:{value:(_vm.templateType),callback:function ($$v) {_vm.templateType=$$v},expression:"templateType"}}),_c('v-btn',{staticClass:"mb-3",attrs:{"color":"success","disabled":_vm.templateType == null},on:{"click":function($event){return _vm.$router.push(("/dashboard/templates/create?type=" + _vm.templateType))}}},[_vm._v(" "+_vm._s(_vm.$t('general.add-new'))+" ")])],1)],1),(_vm.templates && _vm.templates[0] && _vm.templates[0].subject)?_c('v-data-table',{attrs:{"loading":_vm.loading,"headers":_vm.headers,"items":_vm.templates},scopedSlots:_vm._u([{key:"header",fn:function(ref){
var props = ref.props;
return [_c('thead',[_c('tr',_vm._l((props.headers),function(header){return _c('th',{key:header.text},[_vm._v(" "+_vm._s(_vm.$t('general.' + header.text.toLowerCase()))+" ")])}),0)])]}},{key:"item.type",fn:function(ref){
var item = ref.item;
return [(item.type)?_c('span',[_c('span',{domProps:{"innerHTML":_vm._s(item.type)}})]):_vm._e()]}},{key:"item.subject",fn:function(ref){
var item = ref.item;
return [(item.subject)?_c('span',[_c('span',{domProps:{"innerHTML":_vm._s(item.subject)}})]):_vm._e()]}},{key:"item.body",fn:function(ref){
var item = ref.item;
return [(item.body)?_c('span',[(item.body.length > 30)?_c('span',[_c('span',{domProps:{"innerHTML":_vm._s(item.body.slice(0, 30) + ' ...')}})]):_c('span',[_c('span',{domProps:{"innerHTML":_vm._s(item.body)}})])]):_vm._e()]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.editItem(item)}}},[_vm._v(" mdi-pencil ")]),_c('v-icon',{attrs:{"small":""},on:{"click":function($event){return _vm.deleteItem(item)}}},[_vm._v(" mdi-delete ")])]}}],null,false,1729484059)}):_vm._e(),_c('v-dialog',{attrs:{"max-width":"500px"},model:{value:(_vm.dialogDelete),callback:function ($$v) {_vm.dialogDelete=$$v},expression:"dialogDelete"}},[_c('v-card',[_c('v-card-title',{staticClass:"text-h5"},[_vm._v(_vm._s(_vm.$t('general.are-you-sure-you-want'))+" ")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.closeDelete}},[_vm._v(_vm._s(_vm.$t('general.cancel'))+" ")]),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.deleteItemConfirm}},[_vm._v(_vm._s(_vm.$t('general.ok'))+" ")]),_c('v-spacer')],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }