<template>
    <user-dashboard-layout>
        <v-row>
            <v-col>
                <v-select
                    v-model="templateType"
                    :items=unboundTypes
                    :label="`${$t('general.template-select-placeholder')}:`"
                    outlined
                ></v-select>
                <v-btn
                    color="success"
                    class="mb-3"
                    :disabled="templateType == null"
                    @click="$router.push(`/dashboard/templates/create?type=${templateType}`)">
                    {{ $t('general.add-new') }}
                </v-btn>
            </v-col>
        </v-row>
        <v-data-table
            v-if="templates && templates[0] && templates[0].subject"
            :loading="loading"
            :headers="headers"
            :items="templates"
        >
            <template v-slot:header="{ props }">
                <thead>
                <tr>
                    <th v-for="header in props.headers" :key="header.text">
                        {{ $t('general.' + header.text.toLowerCase()) }}
                    </th>
                </tr>
                </thead>
            </template>
            <template v-slot:item.type="{ item }">
                <span v-if="item.type">
                    <span v-html="item.type"></span>
                </span>
            </template>
            <template v-slot:item.subject="{ item }">
                <span v-if="item.subject">
                    <span v-html="item.subject"></span>
                </span>
            </template>
            <template v-slot:item.body="{ item }">
                <span v-if="item.body">
                    <span v-if="item.body.length > 30">
                        <span v-html="item.body.slice(0, 30) + ' ...'"></span>
                    </span>
                    <span v-else>
                        <span v-html="item.body"></span>
                    </span>
                </span>
            </template>
            <template v-slot:item.actions="{ item }">
                <v-icon small class="mr-2" @click="editItem(item)">
                    mdi-pencil
                </v-icon>
                <v-icon small @click="deleteItem(item)">
                    mdi-delete
                </v-icon>
            </template>
        </v-data-table>
        <v-dialog v-model="dialogDelete" max-width="500px">
            <v-card>
                <v-card-title class="text-h5"
                >{{ $t('general.are-you-sure-you-want') }}
                </v-card-title
                >
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="blue darken-1"
                        text
                        @click="closeDelete"
                    >{{ $t('general.cancel') }}
                    </v-btn
                    >
                    <v-btn
                        color="blue darken-1"
                        text
                        @click="deleteItemConfirm"
                    >{{ $t('general.ok') }}
                    </v-btn
                    >
                    <v-spacer></v-spacer>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </user-dashboard-layout>
</template>

<script>
import UserDashboardLayout from "../../../layouts/UserDashboardLayout";
import {mapActions, mapGetters, mapMutations} from "vuex";

export default {
    name: "dashboard.templates",
    components: {UserDashboardLayout},
    data: () => ({
        templateType: null,
        headers: [
            {
                text: "Subject",
                value: "subject"
            },
            {
                text: "Body",
                value: "body"
            },
            {
                text: "Type",
                value: "type"
            },
            {
                text: "Actions",
                value: "actions"
            }
        ],
        loading: false,
        dialogDelete: false,
        editedIndex: -1,
    }),
    computed: {
        ...mapGetters(["defaultTemplate", "template", "templates", "unboundTypes"]),
    },
    methods: {
        ...mapMutations(["setTemplate"]),
        ...mapActions(["storeTemplate", "setTemplates", "updateTemplate", "destroyTemplate", "fetchUnboundTemplateTypes"]),
        async init() {
            this.loading = true
            await this.setTemplates()
            await this.fetchUnboundTemplateTypes()
            this.templateType = this.unboundTypes[0] ?? null;
            this.loading = false
        },
        editItem(item) {
            this.$router.push({name: 'dashboard.templates.edit', params: item, query: {type: item.type}})
        },
        deleteItem(item) {
            this.editedIndex = this.templates.findIndex(
                i => i.id === item.id
            );
            this.setTemplate(item);
            this.dialogDelete = true;
        },
        closeDelete() {
            this.dialogDelete = false;
            this.$nextTick(() => {
                this.setTemplate(this.defaultTemplate);
                this.editedIndex = -1;
            });
        },
        async deleteItemConfirm() {
            await this.destroyTemplate(this.template);
            await this.init();
            this.closeDelete();
        },
    },
    mounted() {
        this.init();
    },
    watch: {
        dialogDelete(val) {
            val || this.closeDelete();
        }
    },
}
</script>

<style>
thead.v-data-table-header {
    display: none !important;
}
</style>
